import React from "react";
import Layout from "../components/Layout";
import AboutStyles from "./AboutUs.module.scss";
import { Helmet } from "react-helmet";

function About() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Adobe Experience Manager architects with strong enterprise experience.
        </title>
        <meta
          name="description"
          content="Hashout is an Adobe and Salesforce partner with specialization in AEM and Salesforce Communities. We have experience providing software development support across technology, logistics, medical and financial domains. "
        ></meta>
      </Helmet>
      <div className={AboutStyles.container}>
        <div className={AboutStyles.introduction}>
          <h1>About Us</h1>
          <p>
            Hashout was founded in the year 2017 with a mission to help
            accelerate the <b>digital experience transformation</b> in companies
            by working with them as an <b>extended team</b> on their{" "}
            <b>transformation roadmap.</b>
          </p>
        </div>
        <div className={AboutStyles.heroSection}>
          <div
            data-sal="zoom-in"
            data-sal-duration="400"
            data-sal-easing="ease"
            className={AboutStyles.heroImageWrapper}
          >
            <img src="../../aboutUs.jpg"></img>
          </div>
          <div className={AboutStyles.heroSummary}>
            <h2>
              We help enterprises innovate at scale with our strong functional
              expertise combined with engineering excellence
            </h2>
            <p>
              Hashout's team of certified experts equip companies with scalable
              digital foundations leveraging the right choice of design,
              technology, and processes. Our digital experience solutions,
              accelerator tools, and integration services empower enterprises
              across all industries to significantly expand their digital
              footprint by providing engaging and compelling customer
              experiences across all channels and customer touchpoints.
              <br />
              Hashout has successfully delivered customer experience platforms
              for top brands across technology, healthcare, logistics, retail
              and financial services industries.
            </p>
          </div>
        </div>
        {/*<div className={AboutStyles.simpleSection}>
          <p>
            Our digital and support experts leverage decades of experience and
            expertise in delivering best in class Experience and Marketing
            solutions.
          </p>
  </div>*/}
        <div className={AboutStyles.expertiseSection}>
          <div className={AboutStyles.expertiseCard}>
            <img src="../../inovative-partner.svg"></img>
            <h6>Innovative Partner</h6>
            <p>
              Our engineers become an integral part of your team making us your
              true partner in innovation.
            </p>
          </div>
          <div className={AboutStyles.expertiseCard}>
            <img src="../../enterprise-edge.svg"></img>
            <h6>Enterprise Edge</h6>
            <p>
              Our expertise in designing, developing and deploying systems at
              scale brings the best out.
            </p>
          </div>
          <div className={AboutStyles.expertiseCard}>
            <img src="../../multi-channel.svg"></img>
            <h6>Multi-Channel Experience</h6>
            <p>
              Relish the world-class digital expertise delivered by our team
              across Web, Mobile, VR, TvOs.
            </p>
          </div>
          <div className={AboutStyles.expertiseCard}>
            <img src="../../Cloud expertise.svg"></img>
            <h6>Cloud Expertise</h6>
            <p>
              Product Development becomes a <br />
              cake-walk with our expertise in AEM, GCP and AWS.
            </p>
          </div>
        </div>
        {/*<div className={AboutStyles.aboutFounders}>
          <h2>Culpa laborum elit id et adipisicing proident culpa</h2>
          <p>
            Ex nostrud cillum dolor adipisicing elit non ad aliquip irure. Non
            labore eiusmod consectetur cupidatat. Do ut adipisicing eiusmod
            velit voluptate officia commodo magna irure. Culpa laborum elit id
            et adipisicing proident culpa reprehenderit et Lorem ex sunt
            deserunt a met ad labore.
          </p>
  </div>*/}
        <div className={AboutStyles.founderSection}>
          <h2>Our Management Team</h2>
          <div className={AboutStyles.founderWrapper}>
            <div className={AboutStyles.founderImageWrapper}>
              <img src="../../shankar-krishnan.svg"></img>
            </div>
            <div className={AboutStyles.founderBioWrapper}>
              <h5>Shankar Krishnan</h5>
              <h6>Co-founder and CEO</h6>
              <p>
                Shankar’s creative problem solving skills with a blend of
                effective people management makes him a natural leader. Post his
                MS in Computer Science from Western Kentucky University (USA),
                he has worked with large product companies such as Citrix,
                WebMethods and Avaya in a career spanning 15 years. He is known
                for his customer-centricity and expertise across Adobe
                Experience Manager, Java,– domains. This enables him to drive
                the delivery of time-critical projects with the highest
                standards of quality.
              </p>
              <p>
                He is passionate about gadgets and automobiles. He enjoys long
                drives, playing badminton and Cricket.
              </p>
              <p>
                At Hashout, Shankar is driving the vision of impactful digital
                transformation for customers and multi-year growth plan.
              </p>
            </div>
          </div>
          <div
            className={`${AboutStyles.orderChange} ${AboutStyles.founderWrapper}`}
          >
            <div className={AboutStyles.founderImageWrapper}>
              <img src="../../shivanand-yeurkar.svg"></img>
            </div>
            <div className={AboutStyles.founderBioWrapper}>
              <h5>Shivanand Yeurkar</h5>
              <h6>Co-founder and CTO</h6>
              <p>
                A Solution Architect with 15 years of professional experience,
                Shivanand is a passionate coder and has developed a strong
                technical expertise which spans Cloud, CRM and Digital
                Experience Platforms. Prior to co-founding Hashout, he was a
                part of senior leadership with companies such as Citrix, Societe
                Generale, Headstrong, Ocwen where he developed a unique
                excellence in AEM (Adobe Experience Manager), GCP (Google Cloud
                Platform) and AWS (Amazon Web Services) domains.
              </p>
              <p>
                He loves cycling and running, never missing a chance to
                participate in cyclothon and duathlon.
              </p>
              <p>
                At Hashout, Shivanand plays a vital role in building exceptional
                engineering talent and deliver digital experiences.
              </p>
            </div>
          </div>
          <div className={AboutStyles.founderWrapper}>
            <div className={AboutStyles.founderImageWrapper}>
              <img src="../../Chella-Kumar.png"></img>
            </div>
            <div className={AboutStyles.founderBioWrapper}>
              <h5>Chella Kumar</h5>
              <h6>Head - Support Solutions and Hashout Labs</h6>
              <p>
                Chella Kumar comes with 13 years of support services experience
                and has led award winning digital support transformation
                projects across large enterprises like Yahoo! and Citrix. Having
                started his career as a Technical Support engineer, he has a
                strong functional understanding of various support functions
                like quality, training, WFM and knowledge management along with
                various tools and processes. This helps him take a consultative
                approach to projects and deliver on programs and systems with a
                unique touch of empathy. With his product mindset, data driven
                decision making skills and experience in implementing best in
                class support systems, Chella has become a go to person for
                functional and technical questions among his peers across the
                industry.
              </p>
              <p>
                During his spare time Chella likes taking up DIY projects around
                the house and blogs regularly on LinkedIn to share his
                knowledge.
              </p>
              <p>
                At Hashout, Chella Kumar fuels product innovation through
                Hashout Labs and leads Support Experience practices.
              </p>
            </div>
          </div>
          <div
            className={`${AboutStyles.orderChange} ${AboutStyles.founderWrapper}`}
          >
            <div className={AboutStyles.founderImageWrapper}>
              <img
                src="../../shivanand-yeurkar.svg"
                style={{ visibility: "hidden" }}
                className={AboutStyles.hide}
              ></img>
            </div>
            <div className={AboutStyles.founderBioWrapper}>
              <h5>Mille Mathew</h5>
              <h6>Vice President, Sales</h6>
              <p>
                Millie brings more than 14 years of diverse experience with IT
                consulting and services; product and project engineering
                consulting across multiple verticals. She has demonstrated her
                ability to lead sales teams and fostering employee engagement to
                develop strong relationships with our customers around the
                world. She is passionate about partnering with customers and
                helping them solve their problems on digital transformation,
                employee and customer experiences and support to help drive
                greater success for our customers.
              </p>
              <p>
                In her spare time, she loves to build Lego projects with her
                kids and explore all the wonderful trails that Texas has to
                offer.
              </p>

              <p>
                At Hashout, Millie provides strategic leadership to our sales
                team supporting the Americas and Europe markets.
              </p>
            </div>
          </div>
        </div>
        {/*<div className={AboutStyles.manageSection}>
          <h2>Management & Advisory Team</h2>
          <div className={AboutStyles.managementCardWrapper}>
            <div className={AboutStyles.managementCard}>
              <img src="../../manage1.svg"></img>
              <p>
                Ex nostrud cillum dolor adipisicing elit non ad aliquip irure.
                Non labore eiusmod consectetur cupidatat. Do ut adipisicing
                eiusmod velit voluptate officia commodo magna irure. Culpa
                laborum elit id et adipisicing.
              </p>
              <h5>Kirsten Oelrich</h5>
              <h6>Co-founder</h6>
            </div>
            <div className={AboutStyles.managementCard}>
              <img src="../../manage2.svg"></img>
              <p>
                Ex nostrud cillum dolor adipisicing elit non ad aliquip irure.
                Non labore eiusmod consectetur cupidatat. Do ut adipisicing
                eiusmod velit voluptate officia commodo magna irure. Culpa
                laborum elit id et adipisicing.
              </p>
              <h5>Kirsten Oelrich</h5>
              <h6>Co-founder</h6>
            </div>
            <div className={AboutStyles.managementCard}>
              <img src="../../manage3.svg"></img>
              <p>
                Ex nostrud cillum dolor adipisicing elit non ad aliquip irure.
                Non labore eiusmod consectetur cupidatat. Do ut adipisicing
                eiusmod velit voluptate officia commodo magna irure. Culpa
                laborum elit id et adipisicing.
              </p>
              <h5>Kirsten Oelrich</h5>
              <h6>Co-founder</h6>
            </div>
          </div>
                </div>
        <div className={AboutStyles.viewAll}>
          <a href="#">
            view all <img src="../../RedVector.svg"></img>
          </a>
        </div>*/}
      </div>
    </Layout>
  );
}

export default About;
